import type { NextPage } from "next";
import { client } from "../lib/apollo";
import { GET_PAGE } from "../queries/getPage";
import { GET_PROJECTS } from "../queries/getProjects";
import { GetStaticProps } from "next";
import styled from "styled-components";
import Header from "../components/Layout/Header";
import GET_NAV_DATA from "../queries/getNavData";
import { Project } from "../components/Project";
import { Footer } from "../components/Layout/Footer";
import { SEOHeader } from "../components/Layout/SEOHeader";
import {
  Page as PageType,
  NavDataQuery as NavDataType,
  ProjectsQuery as ProjectType,
  Project as SingleProjectType,
} from "../generated/gql/graphql";

const Grid = styled.div`
  display: grid;
  grid-gap: 26px;
  padding: 16px;
  margin-left: 150px;
  margin-right: 150px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 2560px) {
    margin-left: 424px;
    margin-right: 424px;
  }

  @media (max-width: 2560px) {
    margin-left: 224px;
    margin-right: 224px;
  }

  @media (max-width: 1800px) {
    margin-left: 104px;
    margin-right: 104px;
  }

  @media (max-width: 1480px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (max-width: 1080px) {
    margin-left: 24px;
    margin-right: 24px;
    grid-gap: 4px;
    padding: 0px;
    margin-top: -8px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 640px) {
    margin-left: 12px;
    margin-right: 12px;
  }
`;

const Container = styled.main``;

const SEOTitle = styled.h1`
  font-size: 0;
`;
interface HomeType {
  projects: ProjectType;
  navData: NavDataType;
}

export function Home({ projects, navData }: HomeType) {
  return (
    <>
      <SEOHeader description="Portfolio website Showcasing highlighed projects containing art and tv makeup"></SEOHeader>
      <Header navData={navData} highlighted={"WORK"}></Header>
      <SEOTitle>Makeup artist Iceland</SEOTitle>
      <Container>
        <Grid>
          {projects.projects.map((project, key) => (
            <Project project={project} key={key}></Project>
          ))}
        </Grid>
      </Container>
      <Footer isFixed={false}></Footer>
    </>
  );
}

export default Home;

export const getStaticProps: GetStaticProps = async (context) => {
  const projectData = await client.query<ProjectType>({
    query: GET_PROJECTS,
  });
  const navData = await client.query<NavDataType>({
    query: GET_NAV_DATA,
  });
  return {
    props: {
      projects: projectData.data,
      navData: navData.data,
    },
  };
};
