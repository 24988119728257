import styled from "styled-components";
import Link from "next/link";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { triggerEvent } from "../utils/GoogleTagManager";

const GridItem = styled(motion.div)`
  box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
  background-color: white;
  text-align: center;
  padding-top: -12px;
  background-color: gray;
  margin-top: -10px;
  height: 40rem;
  @media (width > 1480px) {
    height: 50rem;
  }

  :hover {
    h1 {
      opacity: 1;
    }
  }
  @media (width > 2200px) {
    height: 60rem;
  }
`;

const Image = styled.div`
  background-image: ${(props: { backgroundImage?: string }) =>
    `url(${props.backgroundImage})`};
  height: 40rem;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (width > 1480px) {
    height: 50rem;
  }
  @media (width > 2200px) {
    height: 60rem;
  }
`;

const ProjectTitle = styled.h1`
  font-size: 40px;
  letter-spacing: 0.15em;
  opacity: 0.6;
  padding-top: 500px;
  color: white;
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1080px) {
    padding-top: 400px;
    font-size: 28px;
    letter-spacing: 0.15em;
  }
`;

interface ProjectProps {
  project: {
    __typename?: "Project";
    slug: string;
    tags: Array<string>;
    title: string;
    coverImage?: {
      __typename?: "Asset";
      id: string;
      url: string;
      width?: number | null;
      height?: number | null;
    } | null;
  };
}

export const Project = ({ project }: ProjectProps) => {
  const router = useRouter();
  const handleClick = (e: any) => {
    e.preventDefault();
    router.push(`projects/${project.slug}`);
    triggerEvent({
      eventName: "project_view",
      category: "none",
      label: `slug_${project.slug}`,
      value: 0,
    });
  };
  return (
    <motion.a
      onClick={handleClick}
      whileHover={{ scale: [null, 1.02, 1.02], opacity: 0.8 }}
      href={`projects/${project.slug}`}
    >
      <GridItem
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Image backgroundImage={project?.coverImage?.url}>
          <ProjectTitle>{project.title}</ProjectTitle>
        </Image>
      </GridItem>
    </motion.a>
  );
};
