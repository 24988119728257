import React, { useState } from "react";
import styled from "styled-components";
import Link from "next/link";
import {
  Project as ProjectType,
  Page as PageType,
  NavDataQuery as NavDataType,
} from "../../generated/gql/graphql";
import Image from "next/image";

const Nav = styled.nav`
  padding: 0 20px;
  min-height: 9vh;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1080px) {
    min-height: 8vh;
    padding-right: 40px;
  }
`;

const LogoContainer = styled.div`
  margin: auto;
  @media (max-width: 1080px) {
    margin: 0px;
  }
`;

const Logo = styled.a`
  font-size: 32px;
  letter-spacing: 0.2em;
  font-weight: 200;
  padding-top: 8px;
  @media (max-width: 1080px) {
    font-size: 16px;
    padding-top: 4px;
  }
`;

const DescriptionLogo = styled.div`
  color: black;
  opacity: 0.5;
  margin: auto;
  letter-spacing: 0.1em;
  font-size: 10px;
  width: 195px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1080px) {
    padding-left: 2px;
    margin: 0px;
    font-size: 8px;
  }
`;

const Menu = styled.div`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  @media (max-width: 1080px) {
    display: none;
  }
`;

const Item = styled.li`
  color: black;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  text-decoration: ${(props: { isHighlight: boolean }) =>
    props.isHighlight ? "underline" : "none"};
  text-decoration-thickness: ${(props: { isHighlight: boolean }) =>
    props.isHighlight ? "2px" : "0px"};

  @media (max-width: 1080px) {
    margin-bottom: 20px;
  }
`;

const MobileLink = styled.a`
  padding-top: 160px;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 200px;
  display: ${(props: { open: boolean }) => (props.open ? "contents" : "none")};
`;

const StyledLink = styled.div`
  all: unset;
`;

const Overlay = styled.div`
  position: absolute;
  height: ${(props: { open: boolean }) => (props.open ? "100vh" : 0)};
  pointer-events: ${(props: { open: boolean }) =>
    props.open ? "auto" : "none"};
  width: 100%;
  z-index: 99;
  background: white;
  transition: height 0.4s ease-in-out;
  text-align: center;
  @media (min-width: 1080px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`
  list-style: none;
  position: absolute;
  padding-top: 100px;
  left: 45%;
  top: 45%;
  transform: translate(-50%, -50%);
  letter-spacing: 0.15em;
  li {
    opacity: ${(props: { open: boolean }) => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 75px 0px;
    transition: opacity 0.4s ease-in-out;
  }

  li:nth-child(2) {
    margin: 50px 0px;
  }
`;

const HamburgerButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: sticky;
  top: 15px;
  right: 0px;
  z-index: 20;
  display: none;
  @media (max-width: 1080px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    margin-top: 2px;
    margin-right: -22px;
  }
  div {
    width: 2rem;
    height: 1px;
    background-color: ${(props: { open: boolean }) =>
      props.open ? "black" : "black"};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${(props: { open: boolean }) =>
        props.open ? "rotate(45deg)" : "rotate(0)"};
    }
    &:nth-child(2) {
      transform: ${(props: { open: boolean }) =>
        props.open ? "translateX(100%)" : "translateX(0)"};
      opacity: ${(props: { open: boolean }) => (props.open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${(props: { open: boolean }) =>
        props.open ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`;

const TitleContainer = styled.div`
  margin: auto;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  color: black;
  @media (max-width: 1080px) {
    width: 300px;
    padding-left: 2px;
  }
`;

const InstagramContainer = styled.a`
  position: absolute;
  right: 0;
  margin-right: 140px;
  margin-bottom: 40px;
  @media (max-width: 1080px) {
    display: none;
  }

  @media (max-width: 1280px) {
    margin-right: 40px;
  }
`;

const StyledFotter = styled(Item)`
  font-size: 8px !important;
  margin: 20px !important;
  width: 200px;
`;

const MobileInstagram = styled.div`
  padding-top: 120px;
`;

const StyledMobileLink = styled(Link)`
  color: black;
`;

interface HeaderProps {
  navData: NavDataType;
  highlighted: string;
}

const Header = ({ navData, highlighted }: HeaderProps) => {
  const [navOpen, setNav] = useState(false);
  const [hover, setHover] = useState(false);

  if (navOpen) {
    if (typeof window !== "undefined") {
      document.body.style.overflow = "hidden";
    }
  } else {
    if (typeof window !== "undefined") {
      document.body.style.overflow = "visible";
    }
  }
  const [initialRenderComplete, setInitialRenderComplete] =
    React.useState(false);
  // This useEffect will only run once, during the first render
  React.useEffect(() => {
    // Updating a state causes a re-render
    setInitialRenderComplete(true);
  }, []);
  // initialRenderComplete will be false on the first render and true on all following renders
  if (!initialRenderComplete) {
    // Returning null will prevent the component from rendering, so the content will simply be missing from
    // the server HTML and also wont render during the first client-side render.
    return null;
  } else {
    return (
      <>
        <Nav>
          <InstagramContainer
            target="_blank"
            href="https://www.instagram.com/rannveigola/"
          >
            <Image
              src={"https://media.graphassets.com/jkv9nrqRQQGKcG9mIe6d"}
              height={22}
              width={22}
            />
          </InstagramContainer>
          <LogoContainer>
            <TitleContainer>
              <Logo href={"/"}>{navData?.nav?.websiteTitle}</Logo>
            </TitleContainer>

            <DescriptionLogo>{navData?.nav?.websiteSubTitle}</DescriptionLogo>
            <Menu>
              {navData?.nav?.pages.map((item: any, i: number) => {
                return (
                  <Item isHighlight={item.title === highlighted} key={i}>
                    <Link href={`/${item.slug}`}>{item.title}</Link>
                  </Item>
                );
              })}
            </Menu>
          </LogoContainer>

          <HamburgerButton
            className="fas fa-bars"
            onClick={() => setNav((c) => !c)}
            open={navOpen}
          >
            <div />
            <div />
            <div />
          </HamburgerButton>
        </Nav>
        <Overlay open={navOpen}>
          <OverlayMenu open={navOpen}>
            {navData?.nav?.pages.map((item: any, key: number) => {
              return (
                <>
                  {item.slug === "home" ? (
                    <Item
                      isHighlight={item.title === highlighted && hover}
                      key={key}
                    >
                      <StyledLink onClick={() => setNav(false)}>
                        <StyledMobileLink href={`/${item.slug}`}>
                          {item.title}
                        </StyledMobileLink>
                      </StyledLink>
                    </Item>
                  ) : (
                    <Item isHighlight={item.title === highlighted} key={key}>
                      <Link href={`/${item.slug}`}>{item.title}</Link>
                    </Item>
                  )}
                </>
              );
            })}
            <MobileLink
              open={navOpen}
              target="_blank"
              href="https://www.instagram.com/rannveigola/"
            >
              <MobileInstagram>
                <Image
                  src={"https://media.graphassets.com/jkv9nrqRQQGKcG9mIe6d"}
                  height={22}
                  width={22}
                />
              </MobileInstagram>
            </MobileLink>
            <StyledFotter isHighlight={false}>
              {"RANNVEIG ÓLA 2022"}
            </StyledFotter>
          </OverlayMenu>
        </Overlay>
      </>
    );
  }
};

export default Header;
