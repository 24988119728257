import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  margin: auto;
  padding-top: 22px;
  bottom: 0;
  background: white;
  font-size: 10px;
  height: 60px;
  width: 75%;
  position: ${(props: { isFixed: boolean }) =>
    props.isFixed ? "fixed" : "relative"};
  left: 50%;
  margin-left: -37.5%;
  text-align: center;
  letter-spacing: 0.25em;
`;

const SEOFooter = styled.footer``;

export interface FooterProps {
  isFixed: boolean;
}

export const Footer = ({ isFixed }: FooterProps) => {
  return (
    <SEOFooter>
      <FooterContainer isFixed={isFixed}>RANNVEIG ÓLA 2022</FooterContainer>
    </SEOFooter>
  );
};
