import Head from "next/head";

interface HeaderProps {
  description: string;
}
export const SEOHeader = ({ description }: HeaderProps) => {
  return (
    <Head>
      <title>Rannveig Óla | makeup and hair artist</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta
        name="description"
        content={`Rannveig Óladóttir makeup and hair artist based in Iceland. ${description}`}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href="/favicon.png" />
    </Head>
  );
};
